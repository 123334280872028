import React from "react";

import "./PaymentConfirmed.scss";

const PaymentConfirmed = () => {
  return (
    <div className="payment-confirmed">
      <div className="container">
        <div className="text">
          <h2>Obrigado!</h2>
          <p>Em breve você receberá as instruções em seu email.</p>
        </div>
        <div className="footer">
          <img
            loading="lazy"
            src="https://emersonbroga.com/e/assets/emersonbroga-logo-name-white.png"
            alt="Logo Emerson Broga"
          />
        </div>
      </div>
    </div>
  );
};
export default PaymentConfirmed;
